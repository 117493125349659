import React from 'react'
import Layout from '../components/Layout'

export default class IndexPage extends React.Component {
  render() {
    

    return (
      <Layout>
       <div className="hero is-primary is-bold">
           <div className="hero-body">
               <div className="container">
                   <div className="columns is-centered">
                       <div className="column is-12 is-10-fullhd">
                           <div className="columns is-vcentered">
                                <div className="column is-half">
                                     <div className="title"> 📬 Newsletter </div>
                                     <div className="subtitle"> Receive and feature updates must-read articles </div>
                                </div>
                        <div className="column is-half">
                            <form action="https://netlify.us19.list-manage.com/subscribe/post?u=55eba3948c99da67b9f002516&amp;id=f7902687b9"
                                method="post" target="_blank" validate=""> <input className="is-hidden" name="b_53631bf5dc7e894054ae15edf_0a862d88fd"
                                    tabindex="-1" value=""/>
                                <div className="field has-addons">
                                    <div className="control is-expanded"> <input className="input is-medium" name="EMAIL"
                                            placeholder="Enter your email" required="" type="email" /> </div>
                                    <div className="control"> <button className="button is-primary is-inverted is-medium" type="submit">Subscribe</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
      </Layout>
    )
  }
}


